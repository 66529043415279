import { startStimulusApp } from '@symfony/stimulus-bridge';
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!../vue/views',
    true,
    /\.(j|t)sx?$/
));

import { registerVueControllerComponents } from '@symfony/ux-vue';
registerVueControllerComponents(require.context('../vue/views', true, /\.vue$/, 'lazy'));

// use for pinia store
import {createPinia} from 'pinia';
document.addEventListener('vue:before-mount', (event) => {
    const {
        app,
    } = event.detail;

    const pinia = createPinia()
    app.use(pinia);
});

import {onFind} from "@elements/init-modules-in-scope";

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lightbox from '@elements/lightbox';
lightbox.init({
    plugins: ['video']
});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as floatingLabel from '@elements/floating-labels';
floatingLabel.init();

import * as nav from './nav';
nav.init();

import * as video from './video';
video.init();

import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init();

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import Tabs from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tabs(element);
});

import Modal from 'bootstrap/js/dist/modal';
onFind('[data-bs-toggle="modal"]',function (element) {
    new Modal(element);
});

import * as fadeIn from './fade-in';
fadeIn.init();

import * as dropdownSelect from './dropdown-select';
dropdownSelect.init();

import * as conditionalForm from './conditional-form.js';
conditionalForm.init();

import * as accountLogin from './account-login.js';
accountLogin.init();


import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init({
    submitOnChange: (matchMedia('(min-width: 768px)').matches)
});

import * as tracking from "@elements/tracking";
let options = {debug: true}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as trackingExtension from './tracking-extension';
trackingExtension.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init();

import * as showPassword from './show-password';
showPassword.init();

import * as alert from './alert-bar';
alert.init();